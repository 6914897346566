import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mission from '../pages/mission'
import Login from '../pages/login'
import Stack from '@mui/material/Stack';
import DeckIcon from '@mui/icons-material/Deck';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArticleIcon from '@mui/icons-material/Article';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Auth } from 'aws-amplify';

const drawerWidth = 240;
//`-${drawerWidth}px`

const textColor = {
    color: "white"
};
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    //alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            setOpen(!open);
        }

        return;
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (

        <Box sx={{ display: 'grid' }} onKeyDown={toggleDrawer()}>
            <CssBaseline />
            <AppBar position="fixed" style={{ background: 'transparent', boxShadow: 'none' }}>
            <Toolbar>
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none'}), align:'top-left'}}

                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <Router>

                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background: alpha(grey[900], .7),

                        },

                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader >
                        <List component={Stack} direction="row" display={"flex"}>
                            <ListItem style={{justifyContent:'flex-start'}}>
                                <Typography color={"white"} noWrap>Yuriy Games</Typography>
                            </ListItem>
                            <ListItem style={{justifyContent:'flex-end'}} >
                                <IconButton onClick={handleDrawerClose} color="primary" >
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </ListItem>

                        </List>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem button component={Link} to="/">

                            <ListItemIcon>
                               <DeckIcon color="primary"/>
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ style: textColor }} primary="Home">

                            </ListItemText>
                        </ListItem>

                        <ListItem button component={Link} to="/roadmap">

                            <ListItemIcon>
                                <DirectionsCarIcon color="primary"/>
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ style: textColor }} primary="Roadmap">

                            </ListItemText>

                        </ListItem>

                        <ListItem button component={Link} to="/blog">

                            <ListItemIcon>
                                <ArticleIcon color="primary"/>
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ style: textColor }} primary="Developer Blog">

                            </ListItemText>

                        </ListItem>

                    </List>
                    <Divider />
                    <List>

                        <ListItem button component={Link} to="/login">

                            <ListItemIcon>
                                <AccountCircleIcon color="primary"/>
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ style: textColor }} primary="Login">

                            </ListItemText>

                        </ListItem>

                        <ListItem button component={Link} to="/recipes">

                            <ListItemIcon>
                                <AccountCircleIcon color="primary"/>
                            </ListItemIcon>

                            <ListItemText primaryTypographyProps={{ style: textColor }} primary="Recipe">

                            </ListItemText>

                        </ListItem>
                    </List>
                </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <Routes>
                    <Route path="/" element={<Mission />}>

                    </Route>
                    <Route path="/mission" element={<Mission />}>

                    </Route>
                    <Route path="/roadmap" element={<Mission />}/>
                    <Route path="/login" element={<Login />}/>
                </Routes>
            </Main>
            </Router>
        </Box>
    );
}