import React from 'react';
import {useCallback} from 'react';
import Particles from "react-tsparticles";
import logo from './logo.svg';
import './App.css';
import particlesOptions from "./particles.json";
import ResponsiveAppBar from './components/appBar'
import PersistentDrawerLeft from './components/leftDrawer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mission from './pages/mission'
import toggleDrawer from './components/leftDrawer'
import {loadFull} from "tsparticles"
import type {ISourceOptions, Container, Engine} from "tsparticles-engine"

function App() {
    // @ts-ignore
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    // @ts-ignore
    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);


    return (
        <div className="App">
                <Particles init = {particlesInit} loaded={particlesLoaded} options={particlesOptions as ISourceOptions}/>
                <header className="App-header">
                <PersistentDrawerLeft></PersistentDrawerLeft>
                </header>

        </div>
    );
}

export default App;
