import Typography from '@mui/material/Typography';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
Amplify.configure(awsExports);

export default function Login() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Typography>Login</Typography>
      )}
    </Authenticator>
  );
}